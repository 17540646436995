import { SingleDotIcon } from '@marine/icons';
import { momentPostPublishDate } from '@marine/utils';
import { convertTimeMinutesText } from '@marine/utils/src/reading';
import { FC, useMemo } from 'react';
import styles from './PostItemInfo.module.scss';
import { PostItemInfoProps } from './PostItemInfo.props';

const PostItemInfo: FC<PostItemInfoProps> = ({ showPublishDate, publishDate, readingTime }) => {
  const blogReadingTime = useMemo(() => <p>{convertTimeMinutesText(readingTime!)}</p>, [readingTime]);

  return (
    <div className={styles.info}>
      {showPublishDate ? <p>{momentPostPublishDate(publishDate)}</p> : null}

      <p>
        <SingleDotIcon />
      </p>

      {blogReadingTime}
    </div>
  );
};

export { PostItemInfo };
