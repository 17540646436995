import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback } from 'react';
import { useSettings } from 'services/hooks';

extend(utc);

const useFormattedValues = () => {
  const { getSettingByKey } = useSettings();

  const getFormattedDate = useCallback(
    (date: any, isUtc = true) => {
      if (date && dayjs(date).isValid()) {
        if (isUtc) {
          return dayjs.utc(date).local().format(getSettingByKey('date_format'));
        }

        return dayjs(date).format(getSettingByKey('date_format'));
      }

      return '';
    },
    [getSettingByKey],
  );

  const getFormattedDateTime = useCallback(
    (date: any, isUtc = true) => {
      if (date && dayjs(date).isValid()) {
        if (isUtc) {
          return dayjs
            .utc(date)
            .local()
            .format(`${getSettingByKey('date_format')} ${getSettingByKey('time_format')}`);
        }

        return dayjs(date).format(`${getSettingByKey('date_format')} ${getSettingByKey('time_format')}`);
      }

      return '';
    },
    [getSettingByKey],
  );

  return { getFormattedDate, getFormattedDateTime };
};

export { useFormattedValues };
